import {
  Button,
  Grid,
  Box,
  Typography,
  InputAdornment,
  Paper,
} from '@mui/material'
import {
  AccountCircle,
  LoginOutlined,
  Password,
  RequestPage,
} from '@mui/icons-material'

import Controls from '../../../components/commons/Controls'

import logo from '../../../assets/bg-logo.webp'

import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import validator from 'validator'
import { isObjectEmpty } from '../../../helpers/helpers'
import { loginUser } from '../../../actions/authAction'
import { Nominas } from '../Nominas'

const useStyles = makeStyles({
  form: {
    padding: '10%',
  },
  loginButton: {
    backgroundColor: '#DEC65E',
    color: '#00544E',
    fontFamily: 'Nunito',
    width: '100%',
    '&:hover': {
      backgroundColor: 'white',
      color: '#00544E',
    },
  },
  paper: {
    backgroundColor: '#006760',
    boxShadow: 'none',
  },
  '@media (min-width: 900px)': {
    paper: {
      borderRadius: '25px',
      boxShadow: '-4px -4px 18px #008f85, 4px 4px 18px rgba(0, 0, 0, 0.9)',
      backgroundColor: '#006760',
    },
  },
})

export const Login = () => {
  const [errors, setErrors] = useState({})
  const classes = useStyles()
  const dispatch = useDispatch()
  const loggedIn = useSelector((state) => state.auth.loggedIn)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (loggedIn) {
      return <Nominas />
    }
  }, [loggedIn])

  const login = () => {
    const errors = {}
    setErrors(errors)

    if (!validator.isEmail(email)) {
      errors.email = 'El correo es invalido'
    }

    if (validator.isEmpty(password)) {
      errors.password = 'La contraseña no puede estar vacia'
    }

    if (!isObjectEmpty(errors)) {
      setErrors(errors)
      return
    }

    dispatch(loginUser({ email, password }))
      .then((response) => {})
      .catch((err) => {
        setErrors({ auth: 'No se puede iniciar sesión' })
      })
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'hidden',
        backgroundColor: '#00544e',
        paddingTop: '7vh',
      }}
    >
      <Grid
        container
        //columns={{ xs: 12, sm: 8, md: 12 }}
      >
        <Grid item xs={0} sm={0} md={6} key='imagen'>
          <div className='loginLeft'></div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} key='login' className={classes.form}>
          <Paper
            sx={{
              '@media (min-width: 900px)': {
                borderRadius: '25px',
                boxShadow:
                  '-4px -4px 18px #008f85, 4px 4px 18px rgba(0, 0, 0, 0.9)',
                backgroundColor: '#006760',
              },
              backgroundColor: '#00544E',
              boxShadow: 'none',
            }}
            //className={classes.paper}
          >
            <Grid container columns={{ xs: 12, sm: 10, md: 8 }}>
              <Grid item xs={12} sm={12} md={8} key='form'>
                <div className='formLogoContainer'>
                  <img
                    src={logo}
                    className='formLogo'
                    alt='Logo Materiales Preconsa'
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <RequestPage
                    sx={{
                      display: { xs: 'none', md: 'flex' },
                      mr: 1,
                      color: 'white',
                    }}
                  />
                  <Typography
                    noWrap
                    component='a'
                    sx={{
                      mr: 2,
                      display: { xs: 'none', md: 'flex' },
                      fontWeight: 700,
                      fontFamily: 'Nunito',
                      letterSpacing: '.3rem',
                      color: 'white',
                      textDecoration: 'none',
                    }}
                  >
                    NOMINA MATERIALES PRECONSA
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} key='form'>
                <div className='formLogoContainer'>
                  <form>
                    <Grid
                      container
                      colums={{ xs: 12, sm: 10, md: 8 }}
                      rowSpacing={2}
                    >
                      <Grid item xs={0} md={1} />
                      <Grid item xs={12} md={10}>
                        <Controls.Input
                          id='email'
                          type='email'
                          name='email'
                          placeholder='Ingresa tu email'
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <AccountCircle />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </Grid>
                      <Grid item xs={0} md={1} />
                      <Grid item xs={0} md={1} />
                      <Grid item xs={12} md={10}>
                        <Controls.Input
                          id='password'
                          type='password'
                          name='password'
                          placeholder='Ingresa tu contraseña'
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <Password />
                              </InputAdornment>
                            ),
                            disableUnderline: true,
                          }}
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                        />
                      </Grid>
                      <Grid item xs={0} md={1} />
                      <Grid item xs={0} md={1} />
                      <Grid item xs={12} md={10}>
                        <Button
                          startIcon={<LoginOutlined />}
                          className={classes.loginButton}
                          onClick={login}
                        >
                          Iniciar Sesión
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}
