import { FormControl, MenuItem,Select as MuiSelect } from '@mui/material'

export default function Select(props) {
  const { name, value, onChange, options, disabled } = props
  return (
    <FormControl fullWidth variant='standard'>
      <MuiSelect
        sx={{
          backgroundColor: '#F9F9F9',
          borderRadius: '5px',
          
          paddingLeft: '20px',
          fontSize: '14px',
          fontFamily: 'Nunito',
        }}
        variant='outlined'
        name={name}
        value={value}
        onChange={onChange}
        disableUnderline={true}
        displayEmpty
        disabled={disabled}
        renderValue={value !== '' ? undefined : () => 'Selecciona una Opción'}
      >
        {options?.map(({ id, title }) => (
          <MenuItem value={id} key={id}>
            {title}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
