const API_URL = 'http://3.134.104.236:8080'

//Users
export const LOGIN_ENDPOINT = API_URL + '/users/login'
export const REGISTER_USER_ENDPOINT = API_URL + '/users'

//Posts
export const ADD_POST = API_URL + '/posts'
export const GET_ALL_POSTS = API_URL + '/posts'
export const DELETE_POST = API_URL + '/posts'
export const GET_POST_BY_ID = API_URL + '/posts'

//Products
export const ADD_PRODUCT = API_URL + '/products'
export const GET_ALL_PRODUCTS = API_URL + '/products'
export const DELETE_PRODUCT = API_URL + '/products'
export const GET_PRODUCT_BY_ID = API_URL + '/products'

//Concepts
export const ADD_CONCEPT = API_URL + '/concepts'
export const GET_ALL_CONCEPTS = API_URL + '/concepts'
export const DELETE_CONCEPT = API_URL + '/concepts'
export const GET_CONCEPT_BY_ID = API_URL + '/concepts'

//Workers
export const ADD_WORKER = API_URL + '/workers'
export const GET_ALL_WORKERS = API_URL + '/workers'
export const DELETE_WORKER = API_URL + '/workers'
export const GET_WORKER_BY_ID = API_URL + '/workers'

//Nomina
export const ADD_NOMINA = API_URL + '/nominas'
export const GET_ALL_NOMINAS = API_URL + '/nominas'
export const GET_NOMINA_BY_ID = API_URL + '/nominas'
