import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        minWidth: '0px',
        margin: '2px'
    }
})

export const ActionButton = ({color, element, onClick }) => {
    const classes = useStyles()

    return (
        <Button className={`${classes.root}`} color={color} onClick={onClick}>
            {element}
        </Button>
    )
}