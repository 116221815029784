import { Button, Grid } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect } from 'react'
import Controls from '../../../components/commons/Controls'
import { useForm, Form } from '../../../components/commons/Form'
import { ADD_POST, GET_POST_BY_ID } from '../../../helpers/endpoints'
import axios from 'axios'

const SaveButton = styled(Button)(() => ({
  backgroundColor: '#22bb33',
  width: '100%',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0D4613',
  },
}))

const initialValues = {
  name: '',
  area: '',
}

const PuestosForm = ({ setOpenPopup, postId, loadPuestos }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { name, area } = values

  useEffect(() => {
    if (postId !== '') {
      axios.get(`${GET_POST_BY_ID}/${postId}`).then((response) => {
        setValues(response.data)
      })
    }
  }, [])

  const validate = () => {
    let temp = {}

    temp.name = (name || '').length === 0 ? 'Este campo es requerido' : ''
    temp.area = (area || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({ ...temp })

    return Object.values(temp).every((x) => x === '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate()) {
      if (postId !== '') {
        await axios.put(`${ADD_POST}/${postId}`, { name, area })
      } else {
        await axios.post(ADD_POST, values)
      }
      resetForm()
      setValues({})
      setOpenPopup(false)
      loadPuestos()
    }
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5%' }}
    >
      <Form>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label='Puesto'
              id='name'
              type='text'
              name='name'
              value={name}
              onChange={handleInputChange}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controls.Select
              label='Area'
              id='area'
              name='area'
              value={area}
              onChange={handleInputChange}
              options={[
                { id: '', title: 'Selecciona una Opción' },
                { id: '1', title: 'Produccion - Lado Húmedo' },
                { id: '2', title: 'Producción - Lado Seco' },
                { id: '3', title: 'Ventas' },
                { id: '4', title: 'Logística / Choferes' },
                { id: '5', title: 'Administración' },
                { id: '6', title: 'Extras' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SaveButton onClick={handleSubmit}>Guardar Cambios</SaveButton>
          </Grid>
        </Grid>
      </Form>
    </div>
  )
}

export default PuestosForm
