import { Button, Grid } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect } from 'react'
import Controls from '../../../components/commons/Controls'
import { useForm, Form } from '../../../components/commons/Form'
import { ADD_CONCEPT, GET_CONCEPT_BY_ID } from '../../../helpers/endpoints'
import axios from 'axios'

const SaveButton = styled(Button)(() => ({
  backgroundColor: '#22bb33',
  width: '100%',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0D4613',
  },
}))

const initialValues = {
  name: '',
  value: '',
  post: '',
  product: '',
  type: '',
}

const TarifasForm = ({ setOpenPopup, conceptId, loadConcepts }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { name, value, post, product, type } = values

  useEffect(() => {
    if (conceptId !== '') {
      axios.get(`${GET_CONCEPT_BY_ID}/${conceptId}`).then((response) => {
        setValues(response.data)
      })
    }
  }, [conceptId, setValues])

  const validate = () => {
    let temp = {}

    temp.name = (name || '').length === 0 ? 'Este campo es requerido' : ''
    temp.value = (value || '').length === 0 ? 'Este campo es requerido' : ''
    temp.post = (post || '').length === 0 ? 'Este campo es requerido' : ''
    temp.product = (product || '').length === 0 ? 'Este campo es requerido' : ''
    temp.type = (type || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({ ...temp })

    return Object.values(temp).every((x) => x === '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate()) {
      if (conceptId !== '') {
        await axios.put(`${ADD_CONCEPT}/${conceptId}`, {
          name,
          value,
          post,
          product,
          type,
        })
      } else {
        await axios.post(ADD_CONCEPT, values)
      }
      resetForm()
      setValues({})
      setOpenPopup(false)
      loadConcepts()
    }
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5%' }}
    >
      <Form>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12} md={4}>
            <Controls.Input
              label='Concepto'
              id='name'
              type='text'
              name='name'
              value={name}
              onChange={handleInputChange}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controls.Input
              label='Valor'
              id='value'
              type='text'
              name='value'
              value={value}
              onChange={handleInputChange}
              error={errors.value}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controls.Select
              label='Tipo'
              id='type'
              name='type'
              value={type}
              onChange={handleInputChange}
              options={[
                { id: '', title: 'Selecciona una Opción' },
                { id: '1', title: 'Comisión' },
                { id: '2', title: 'Tarifa' },
                { id: '3', title: 'Retención' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label='Puesto'
              id='post'
              type='text'
              name='post'
              value={post}
              onChange={handleInputChange}
              error={errors.post}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label='Producto'
              id='product'
              type='text'
              name='product'
              value={product}
              onChange={handleInputChange}
              error={errors.product}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SaveButton onClick={handleSubmit}>Guardar Cambios</SaveButton>
          </Grid>
        </Grid>
      </Form>
    </div>
  )
}

export default TarifasForm
