import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { NavBar } from './components/commons/Navbar/navbar'
import { Login } from './pages/Dashboard/Login'
import { Nominas } from './pages/Dashboard/Nominas'
import { Productos } from './pages/Dashboard/Productos'
import { Puestos } from './pages/Dashboard/Puestos'
import { Tarifas } from './pages/Dashboard/Conceptos'
import { Provider } from 'react-redux'
import store from './store'
import { PrivateRoute } from './router/PrivateRoute'
import { Trabajadores } from './pages/Dashboard/Workers'

export const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <div>
          <NavBar />
        </div>

        <Routes>
          <Route path='/' element={<Login />}></Route>
          <Route path='/login' element={<Login />}></Route>
          <Route
            path='/nominas'
            element={
              <PrivateRoute>
                <Nominas />
              </PrivateRoute>
            }
          />
          <Route
            path='/puestos'
            element={
              <PrivateRoute>
                <Puestos />
              </PrivateRoute>
            }
          />
          <Route
            path='/conceptos'
            element={
              <PrivateRoute>
                <Tarifas />
              </PrivateRoute>
            }
          />
          <Route
            path='/productos'
            element={
              <PrivateRoute>
                <Productos />
              </PrivateRoute>
            }
          />
          <Route
            path='/trabajadores'
            element={
              <PrivateRoute>
                <Trabajadores />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </Provider>
  )
}
