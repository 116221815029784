import { TextField } from '@mui/material'

export const Input = (props) => {
  const { name, type, value, label, error = null, onChange, ...other } = props
  return (
    <TextField
      sx={{
        backgroundColor: '#F9F9F9',
        borderRadius: '5px',
        width: '100%',
        fontFamily: 'Nunito',
      }}
      type={type}
      variant='outlined'
      autoComplete='off'
      name={name}
      color='success'
      value={value}
      onChange={onChange}
      label={label}
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: '14px',
          fontFamily: 'Nunito',
        },
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  )
}
