import { Input } from "./Input"
import {TextArea} from './Textarea'
import {ActionButton} from './Button'
import Select from "./Select"

const Controls = {
    Input,
    Select,
    TextArea,
    ActionButton
}

export default Controls