import { Add, Close } from '@mui/icons-material'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  Paper,
  TableBody,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useState } from 'react'
import Controls from '../../../components/commons/Controls'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const AddButton = styled(Button)(() => ({
  backgroundColor: 'red',
  borderRadius: '0px',
  width: '100%',
  fontSize: '1.1rem',
  height: '5vh',
  backgroundColor: '#008F85',
  color: 'white',
  fontFamily: 'Nunito',
  '&:hover': {
    backgroundColor: '#004843',
  },
}))

const Subtitle = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '600',
  fontSize: '1.2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const Text = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '600',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const NormalText = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontWeight: '500',
  fontSize: '1rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const NominasForm = ({}) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <AddButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Nueva Nomina
      </AddButton>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: 'relative',
            backgroundColor: '#003632',
            marginBottom: '1%',
          }}
        >
          <Toolbar>
            <IconButton edge='start' onClick={() => setOpen(false)}>
              <Close sx={{ color: 'white' }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, fontFamily: 'Nunito' }}
              variant='h6'
              component='div'
            >
              Nómina
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Subtitle>Area Administrativa</Subtitle>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Gerente General</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={3}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={3}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={3}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={12}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          {/******************************************************************************************************************************/}
          <Grid item xs={12} md={12}>
            <Subtitle>Area de Contabilidad</Subtitle>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Gerente de Contabilidad</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2.4}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={12}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Auxiliar Contable</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2.4}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={12}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Cajera</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2.4}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2.4}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={12}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          {/******************************************************************************************************************************/}
          <Grid item xs={12} md={12}>
            <Subtitle>Area de Ventas</Subtitle>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Vendedor de Mostrador</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Vendedor de Mostrador</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Subtitle>Area de Logistica</Subtitle>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Gerente de Logistica</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Auxiliar de Logistica</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Chofer</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Chofer</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Chofer</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Chofer</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Encargado de Almacen</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Ayudante General</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Ayudante General</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Ayudante General</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Subtitle>Area de Producción</Subtitle>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Gerente de Producción</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <NormalText>Lado Humedo</NormalText>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Controlador</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Transfer 1</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Transfer 2</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <NormalText>Lado Seco</NormalText>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Paletizador 1</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Paletizador 2</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={3}>
            <Text>Puesto</Text>
            <NormalText>Transfer</NormalText>
          </Grid>
          <Grid item xs={9} md={9} sx={{ paddingRight: '10px' }}>
            <Text>Parametros</Text>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Controls.Select
                  label='Días Trabajados'
                  options={[
                    { id: '', title: 'Días trabajados' },
                    { id: 1, title: '1' },
                    { id: 2, title: '2' },
                    { id: 3, title: '3' },
                    { id: 4, title: '4' },
                    { id: 5, title: '5' },
                    { id: 6, title: '6' },
                  ]}
                />
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Tiempo Extra'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Comisiones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input label='Retardos'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input disabled label='Retenciones'></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Controls.Input
                  disabled
                  label='Neto a Recibir'
                ></Controls.Input>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Comisiones</Button>
              </Grid>
              <Grid item xs={2}>
                <Button>Calcular Total</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}

export default NominasForm
