import { Button, Grid } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'
import Controls from '../../../components/commons/Controls'
import { useForm, Form } from '../../../components/commons/Form'
import {
  ADD_WORKER,
  GET_ALL_POSTS,
  GET_WORKER_BY_ID,
} from '../../../helpers/endpoints'
import axios from 'axios'

const SaveButton = styled(Button)(() => ({
  backgroundColor: '#22bb33',
  width: '100%',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0D4613',
  },
}))

const initialValues = {
  firstName: '',
  lastName: '',
  post: '',
  salary: '',
}

const TrabajadoresForm = ({ setOpenPopup, workerId, loadWorkers }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { firstName, lastName, post, salary } = values

  const [options, setOptions] = useState([])

  useEffect(() => {
    if (workerId !== '') {
      axios.get(`${GET_WORKER_BY_ID}/${workerId}`).then((response) => {
        setValues(response.data)
      })
    }
    getOptions()
  }, [])

  const validate = () => {
    let temp = {}

    temp.firstName =
      (firstName || '').length === 0 ? 'Este campo es requerido' : ''
    temp.lastName =
      (lastName || '').length === 0 ? 'Este campo es requerido' : ''
    temp.post = (post || '').length === 0 ? 'Este campo es requerido' : ''
    temp.salary = (salary || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({ ...temp })

    return Object.values(temp).every((x) => x === '')
  }

  const handleSubmit = async (e) => {
    console.log(values)
    e.preventDefault()
    if (validate()) {
      if (workerId !== '') {
        await axios.put(`${ADD_WORKER}/${workerId}`, {
          firstName,
          lastName,
          post,
          salary,
        })
      } else {
        await axios.post(ADD_WORKER, values)
      }
      resetForm()
      setValues({})
      setOpenPopup(false)
      loadWorkers()
    }
  }

  const getOptions = async () => {
    const temp = []
    const response = await axios.get(GET_ALL_POSTS)
    const data = response.data
    for (let index = 0; index < data.length; index++) {
      const element = {
        id: data[index].postId,
        title: data[index].name,
      }
      temp.push(element)
    }
    setOptions(temp)
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5%' }}
    >
      <Form>
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label='Nombre'
              id='firstName'
              type='text'
              name='firstName'
              value={firstName}
              onChange={handleInputChange}
              error={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label='Apellidos'
              id='lastName'
              type='text'
              name='lastName'
              value={lastName}
              onChange={handleInputChange}
              error={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controls.Select
              label='Puesto'
              id='post'
              name='post'
              value={post}
              onChange={handleInputChange}
              error={errors.post}
              options={options}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label='Sueldo Base'
              id='salary'
              type='text'
              name='salary'
              value={salary}
              onChange={handleInputChange}
              error={errors.salary}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SaveButton onClick={handleSubmit}>Guardar Cambios</SaveButton>
          </Grid>
        </Grid>
      </Form>
    </div>
  )
}

export default TrabajadoresForm
