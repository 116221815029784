import { useState } from 'react'
import {
  TableCell as TCell,
  TableHead as THead,
  TablePagination as TPagination,
  TableRow as TRow,
  TableSortLabel as TSortLabel,
} from '@mui/material'
import { Table as Tbl } from '@mui/material'
import { styled } from '@mui/system'

const TableHead = styled((props) => <THead {...props} />)(() => ({
  fontSize: '0.8em',
  letterSpacing: '0.1em',
  textTransform: 'uppercase',
  background: '#003632',
  color: 'white',
  '@media (max-width : 800px)': {
    border: 'none',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
  },
}))

const Table = styled((props) => <Tbl {...props} />)(() => ({
    borderRadius: '0px 0px 10px 10px'
}))

export default function useTable(users, headCells, filterFn) {
  const pages = [5, 10, 25]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const TblContainer = (props) => <Table>{props.children}</Table>

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(cellId)
    }
    return (
      <TableHead>
        <TRow>
          {headCells.map((headCell) => (
            <TCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{color: 'white'}}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(headCell.id)
                  }}
                >
                  {headCell.label}
                </TSortLabel>
              )}
            </TCell>
          ))}
        </TRow>
      </TableHead>
    )
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const TblPagination = () => (
    <TPagination
      component='div'
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={users.length}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const recordAfterPagingAndSorting = () => {
    return stableSort(filterFn.fn(users), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    )
  }

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordAfterPagingAndSorting,
  }
}
