import { useSelector } from 'react-redux'
import { Login } from '../pages/Dashboard/Login'

export const PrivateRoute = ({ component: Component, children }) => {
  const loggedIn = useSelector((state) => state.auth.loggedIn)

  if (!loggedIn) {
    return <Login></Login>
  }
  return children
}
