import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import es from 'date-fns/locale/es/index'
import DateFnsUtils from '@date-io/date-fns'
import {
  Add,
  CalendarMonth,
  Edit,
  ExpandMore,
  Search,
} from '@mui/icons-material'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  Paper,
  TableBody,
  TextField,
  Typography,
  Box,
  InputAdornment,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'
import useTable from '../../../components/commons/Table'
import Controls from '../../../components/commons/Controls'
import { CustomDialog } from '../../../components/commons/Dialog'
import NominasForm from './form'
import axios from 'axios'
import { GET_ALL_NOMINAS } from '../../../helpers/endpoints'

const Title = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontSize: '2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const CustomAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: '0',
  },
  '&:before': {
    display: 'none',
  },
}))

const CustomSummaryTop = styled((props) => (
  <AccordionSummary expandIcon={<ExpandMore />} {...props} />
))(() => ({
  borderRadius: '10px 10px 0px 0px ',
  backgroundColor: '#008F85',
  color: 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 1,
  },
  '&:hover': {
    backgroundColor: '#004843',
  },
}))

const CustomSummaryBottom = styled((props) => (
  <AccordionSummary expandIcon={<ExpandMore />} {...props} />
))(() => ({
  backgroundColor: '#008F85',
  color: 'white',
  flexDirection: 'row-reverse',
  '& .Mui-root.Mui-expanded': {
    backgroundColor: 'red',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 1,
  },
  '&:hover': {
    backgroundColor: '#004843',
  },
}))

const CustomDetails = styled(AccordionDetails)(() => ({
  backgroundColor: '#DFF2F2',
}))

const SearchButton = styled(Button)(() => ({
  backgroundColor: '#003632',
  color: 'white',
  fontFamily: 'Nunito',
  paddingLeft: '20px',
  paddingRight: '20px',
  letterSpacing: '0.1em',
  '&:hover': {
    backgroundColor: '#006B64',
  },
}))

const AddButton = styled(Button)(() => ({
  backgroundColor: 'red',
  borderRadius: '0px',
  width: '100%',
  fontSize: '1.1rem',
  height: '5vh',
  backgroundColor: '#008F85',
  color: 'white',
  fontFamily: 'Nunito',
  '&:hover': {
    backgroundColor: '#004843',
  },
}))

const headCells = [
  { id: 'periodo', label: 'Periodo' },
  { id: 'area', label: 'Area' },
  { id: 'totPerc', label: 'Total de Percepciones' },
  { id: 'status', label: 'Estado' },
  { id: 'edicion', label: 'Ultima Edición' },
  { id: 'acciones', label: 'Acciones' },
]

export const Nominas = () => {
  const [expanded, setExpanded] = useState(false)
  const [nominas, setNominas] = useState([])
  const [nominaId, setNominaId] = useState('')

  const [open, setOpen] = useState(false)
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  useEffect(() => {
    loadNominas()
  }, [])

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(nominas, headCells, filterFn)

  const loadNominas = async () => {
    const result = await axios.get(GET_ALL_NOMINAS)
    setNominas(result.data)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Paper
      sx={{
        paddingTop: '8vh',
        height: '92vh',
        backgroundColor: '#EEEEEE',
        paddingLeft: '5vw',
        paddingRight: '5vw',
      }}
    >
      <Paper
        sx={{ marginTop: '2vh', paddingTop: '2.5vh', paddingBottom: '2.5vh' }}
      >
        <Title>Nominas</Title>
        <Container>
          <CustomAccordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <CustomSummaryTop
              expandIcon={<ExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Filtrar por Fecha</Typography>
            </CustomSummaryTop>
            <CustomDetails>
              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <LocalizationProvider
                    adapterLocale={es}
                    dateAdapter={DateFnsUtils}
                  >
                    <MobileDatePicker
                      inputFormat='dd/MM/yyyy'
                      label='Fecha'
                      renderInput={(params) => (
                        <TextField
                          sx={{ backgroundColor: '#F9F9F9', width: '100%' }}
                          {...params}
                        />
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <CalendarMonth />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <SearchButton startIcon={<Search />}>
                    Filtrar Nomina
                  </SearchButton>
                </Grid>
              </Grid>
            </CustomDetails>
          </CustomAccordion>
          <CustomAccordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
          >
            <CustomSummaryBottom
              expandIcon={<ExpandMore />}
              aria-controls='panel2bh-content'
              id='panel2bh-header'
            >
              <Typography>Filtrar por Area</Typography>
            </CustomSummaryBottom>
            <CustomDetails>
              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controls.Select
                    options={[
                      { id: '', title: 'Selecciona una Opción' },
                      { id: '1', title: 'Produccion - Lado Húmedo' },
                      { id: '2', title: 'Producción - Lado Seco' },
                      { id: '3', title: 'Ventas' },
                      { id: '4', title: 'Logística / Choferes' },
                      { id: '5', title: 'Adminstración' },
                      { id: '6', title: 'Extras' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SearchButton startIcon={<Search />}>
                    Filtrar Nomina
                  </SearchButton>
                </Grid>
              </Grid>
            </CustomDetails>
          </CustomAccordion>
        </Container>
        <Container>
          <NominasForm />
        </Container>
        <Container>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordAfterPagingAndSorting().map((nomina, index) => (
                <TableRow key={nomina.nominaId}>
                  <TableCell>{nomina.rangeDate}</TableCell>
                  <TableCell>{nomina.area}</TableCell>
                  <TableCell>$ 0000.00</TableCell>
                  <TableCell>{nomina.status}</TableCell>
                  <TableCell>{nomina.editionDate}</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setNominaId(nomina.nominaId)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: '#DEC65E',
                            color: 'white',
                            '&:hover': {
                              color: '#DEC65E',
                            },
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Paper>
    </Paper>
  )
}
