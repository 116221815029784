import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@mui/material'
import { Box } from '@mui/material'
import { Toolbar } from '@mui/material'
import { IconButton } from '@mui/material'
import { Typography } from '@mui/material'
import { Menu } from '@mui/material'
import { Container } from '@mui/material'
import { Button } from '@mui/material'
import { MenuItem } from '@mui/material'
import { Logout, Menu as MenuIcon } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../../actions/authAction'

const pages = [
  { id: 'nominas', title: 'Nóminas' },
  { id: 'trabajadores', title: 'Trabajadores' },
  { id: 'puestos', title: 'Puestos' },
  { id: 'conceptos', title: 'Conceptos' },
  { id: 'productos', title: 'Productos' },
]

export const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <AppBar
      position='fixed'
      sx={{ background: 'linear-gradient(90deg,#00544e,#009696)' }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.id}
                  onClick={() => {
                    navigate(`/${page.id}`)
                  }}
                  sx={{ fontFamily: 'Nunito' }}
                >
                  <Typography>{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.id}
                onClick={() => {
                  navigate(`/${page.id}`)
                }}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  fontFamily: 'Nunito',
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}></Box>
          {loggedIn && (
            <IconButton onClick={() => dispatch(logoutUser())}>
              <Logout sx={{ color: 'white' }} />
            </IconButton>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
