import { Button, FormHelperText, Grid } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect } from 'react'
import Controls from '../../../components/commons/Controls'
import { useForm, Form } from '../../../components/commons/Form'
import axios from 'axios'
import { ADD_PRODUCT, GET_PRODUCT_BY_ID } from '../../../helpers/endpoints'

const SaveButton = styled(Button)(() => ({
  backgroundColor: '#22bb33',
  width: '100%',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0D4613',
  },
}))

const initialValues = {
  clave: '',
  descripcion: '',
  plataforma: '',
}

const ProductosForm = ({ setOpenPopup, productId, loadProductos }) => {
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues)

  let { clave, description, platform } = values

  useEffect(() => {
    if (productId !== '') {
      axios.get(`${GET_PRODUCT_BY_ID}/${productId}`).then((response) => {
        setValues(response.data)
      })
    }
  }, [])

  const validate = () => {
    let temp = {}

    temp.clave = (clave || '').length === 0 ? 'Este campo es requerido' : ''
    temp.description =
      (description || '').length === 0 ? 'Este campo es requerido' : ''
    temp.platform =
      (platform || '').length === 0 ? 'Este campo es requerido' : ''

    setErrors({ ...temp })

    return Object.values(temp).every((x) => x === '')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (validate()) {
      if (validate()) {
        if (productId !== '') {
          await axios.put(`${ADD_PRODUCT}/${productId}`, {
            clave,
            description,
            platform,
          })
        } else {
          await axios.post(ADD_PRODUCT, values)
        }
      }
      resetForm()
      setValues({})
      setOpenPopup(false)
      loadProductos()
    }
  }

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', paddingLeft: '5%' }}
    >
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Controls.Input
              label='Clave'
              id='clave'
              type='text'
              name='clave'
              value={clave}
              onChange={handleInputChange}
              error={errors.clave}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controls.Input
              label='Descripción'
              id='description'
              type='text'
              name='description'
              value={description}
              onChange={handleInputChange}
              error={errors.description}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controls.Select
              label='Plataforma'
              id='platform'
              name='platform'
              value={platform}
              onChange={handleInputChange}
              options={[
                { id: 2, title: 'Plataforma 2' },
                { id: 3, title: 'Plataforma 3' },
              ]}
            />
            <FormHelperText>{errors.plataforma}</FormHelperText>
          </Grid>
          <Grid item xs={12} md={12}>
            <SaveButton onClick={handleSubmit}>Guardar Cambios</SaveButton>
          </Grid>
        </Grid>
      </Form>
    </div>
  )
}

export default ProductosForm
