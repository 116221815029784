import { Add, Delete, Edit, ExpandMore, Search } from '@mui/icons-material'
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Grid,
  Paper,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'
import useTable from '../../../components/commons/Table'
import Controls from '../../../components/commons/Controls'
import { CustomDialog } from '../../../components/commons/Dialog'
import axios from 'axios'
import {
  DELETE_WORKER,
  GET_ALL_WORKERS,
  GET_POST_BY_ID,
} from '../../../helpers/endpoints'
import Swal from 'sweetalert2'
import TrabajadoresForm from './form'
import React from 'react'

const Title = styled((props) => <Typography {...props} />)(() => ({
  fontFamily: 'Nunito',
  fontSize: '2rem',
  marginBottom: '2vh',
  display: 'flex',
  justifyContent: 'center',
}))

const CustomAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: '0',
  },
  '&:before': {
    display: 'none',
  },
}))

const CustomSummaryTop = styled((props) => (
  <AccordionSummary expandIcon={<ExpandMore />} {...props} />
))(() => ({
  borderRadius: '10px 10px 0px 0px ',
  backgroundColor: '#008F85',
  color: 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 1,
  },
  '&:hover': {
    backgroundColor: '#004843',
  },
}))

const CustomDetails = styled(AccordionDetails)(() => ({
  backgroundColor: '#DFF2F2',
}))

const SearchButton = styled(Button)(() => ({
  backgroundColor: '#003632',
  color: 'white',
  fontFamily: 'Nunito',
  paddingLeft: '20px',
  paddingRight: '20px',
  letterSpacing: '0.1em',
  '&:hover': {
    backgroundColor: '#006B64',
  },
}))

const AddButton = styled(Button)(() => ({
  borderRadius: '0px',
  width: '100%',
  fontSize: '1.1rem',
  height: '5vh',
  backgroundColor: '#008F85',
  color: 'white',
  fontFamily: 'Nunito',
  '&:hover': {
    backgroundColor: '#004843',
  },
}))

const headCells = [
  { id: 'nombre', label: 'Nombre' },
  { id: 'puesto', label: 'Puesto' },
  { id: 'acciones', label: 'Acciones' },
]

export const Trabajadores = () => {
  const [expanded, setExpanded] = useState(false)
  const [trabajadores, setTrabajadores] = useState([])
  const [workerId, setWorkerId] = useState('')
  const [temp, setTemp] = useState('')

  const [open, setOpen] = useState(false)
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items
    },
  })

  useEffect(() => {
    loadWorkers()
  }, [])

  const { TblContainer, TblHead, TblPagination, recordAfterPagingAndSorting } =
    useTable(trabajadores, headCells, filterFn)

  const loadWorkers = async () => {
    const result = await axios.get(GET_ALL_WORKERS)
    setTrabajadores(result.data)
  }

  const deleteWorker = (id) => {
    Swal.fire({
      title: '¿Estas seguro de eliminar este registro?',
      showConfirmButton: true,
      confirmButtonColor: 'green',
      confirmButtonText: 'Sí, estoy seguro',
      showCancelButton: true,
      cancelButtonColor: 'red',
      cancelButtonText: 'Cancelar',
      icon: 'warning',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${DELETE_WORKER}/${id}`)
        Swal.fire({
          position: 'bottom-end',
          icon: 'success',
          title: 'Registro eliminado exitosamente',
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        Swal.fire({
          position: 'bottom-end',
          icon: 'info',
          title: 'Operacion cancelada',
          showConfirmButton: false,
          timer: 1000,
        })
      }
    })
  }

  const getPostName = (id) => {
    axios
      .get(`${GET_POST_BY_ID}/${id}`)
      .then((response) => setTemp(response.data.name))
    let data = temp
    return data
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Paper
      sx={{
        paddingTop: '8vh',
        height: '92vh',
        backgroundColor: '#EEEEEE',
        paddingLeft: '5vw',
        paddingRight: '5vw',
      }}
    >
      <Paper
        sx={{ marginTop: '2vh', paddingTop: '2.5vh', paddingBottom: '2.5vh' }}
      >
        <Title>Trabajadores</Title>
        <Container>
          <CustomAccordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <CustomSummaryTop
              expandIcon={<ExpandMore />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography>Filtrar</Typography>
            </CustomSummaryTop>
            <CustomDetails>
              <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Controls.Input label='Filtrar trabajador' />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <SearchButton startIcon={<Search />}>
                    Filtrar Trabajador
                  </SearchButton>
                </Grid>
              </Grid>
            </CustomDetails>
          </CustomAccordion>
        </Container>
        <Container>
          <AddButton
            startIcon={<Add />}
            onClick={() => {
              setOpen(true)
              setWorkerId('')
            }}
          >
            Nuevo Trabajador
          </AddButton>
        </Container>
        <Container>
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordAfterPagingAndSorting().map((worker, index) => (
                <TableRow key={worker.workerId}>
                  <TableCell>{`${worker.firstName} ${worker.lastName}`}</TableCell>
                  <TableCell>{getPostName(worker.post)}</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            setWorkerId(worker.workerId)
                            setOpen(true)
                          }}
                          sx={{
                            backgroundColor: '#DEC65E',
                            color: 'white',
                            '&:hover': {
                              color: '#DEC65E',
                            },
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            deleteWorker(worker.workerId)
                            loadWorkers()
                          }}
                          sx={{
                            backgroundColor: 'red',
                            color: 'white',
                            '&:hover': {
                              color: 'red',
                            },
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Container>
      </Paper>
      <CustomDialog
        title='Información Trabajadores'
        openPopup={open}
        setOpenPopup={setOpen}
        maxWidth='sm'
      >
        <TrabajadoresForm
          setOpenPopup={setOpen}
          workerId={workerId}
          loadWorkers={loadWorkers}
        />
      </CustomDialog>
    </Paper>
  )
}
